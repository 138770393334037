import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CalendarPickerSkeleton } from "@mui/x-date-pickers/CalendarPickerSkeleton";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import getDaysInMonth from "date-fns/getDaysInMonth";

export const WeekOptionsForDisplay = [
  { value: 0, label: "Since Last Week" },
  { value: -1, label: "Since Prior Week" },
  { value: -2, label: "Since Prior 2 Weeks" },
  { value: 1, label: "Since Prior Month" },
  { value: 2, label: "Since Last Date Range" },
];

export const ITEM_OPTION_IS = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "No",
  },
];

export const MEASUREMENT_OPTION = [
  {
    value: 3,
    label: "Please select",
  },
  {
    value: 1,
    label: "weight (oz)",
  },
  {
    value: 2,
    label: "volume (fluid oz)",
  },
];

export const ITEM_OPTION_UNIT = [
  {
    value: "case",
    label: "Case",
  },
  {
    value: "unit",
    label: "Unit",
  },
];

export const PRICE_STATUS_OPTION: any = [
  {
    value: 1,
    label: "Active - In Schematic",
  },
  {
    value: 2,
    label: "Sell In Only",
  },
  {
    value: 3,
    label: "Active - PO Only",
  },
  {
    value: 4,
    label: "De-Activated",
  },
];

export const SHOW_ITEM = [
  {
    value: 10,
    label: "10 rows",
  },
  {
    value: 25,
    label: "25 rows",
  },
  {
    value: 50,
    label: "50 rows",
  },
  {
    value: 100,
    label: "100 rows",
  },
  {
    value: 1000,
    label: "Show all",
  },
];

export const SHOW_PRODUCT_ACTIVE = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "Inactive",
  },
  {
    value: "pending",
    label: "Pending",
  },
];

export const WeekOptions = [
  { value: 0, label: "This week" },
  { value: -1, label: "Last week" },
  { value: -2, label: "Last 2 weeks" },
  { value: 1, label: "1 month" },
  { value: 2, label: "Custom Date" },
];

function getRandomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date: Date, { signal }: { signal: AbortSignal }) {
  return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = getDaysInMonth(date);
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = new Date();

export function DashboardDate({ setDataCalendar, dataCalendar }: any) {
  const requestAbortController = React.useRef<AbortController | null>(null);

  const [, setHighlightedDays] = React.useState([1, 2, 15]);

  const fetchHighlightedDays = (date: Date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date: Date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    // setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const onSelcect = (newValue: any) => {
    // setIsLoading(true);
    setDataCalendar(newValue);
    // setFilterValue({
    //   ...filterValue,
    //   from_date: moment(newValue)?.format('YYYY-MM-DD'),
    // });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={dataCalendar}
        onChange={(newValue) => {
          onSelcect(newValue);
        }}
        onMonthChange={handleMonthChange}
        renderInput={(params) => <TextField {...params} />}
        renderLoading={() => <CalendarPickerSkeleton />}
        renderDay={(day, _value, DayComponentProps) => {
          // const isSelected =
          //   !DayComponentProps.outsideCurrentMonth &&
          //   highlightedDays.indexOf(day.getDate()) > 0;

          return <PickersDay {...DayComponentProps} />;
        }}
      />
    </LocalizationProvider>
  );
}
