import styles from "./Dashboard.module.scss";
import "./Dashboard.css";
import { Col, Row, Form, InputGroup, Card } from "react-bootstrap";
import {
  BarChartOptions,
  TIME_SVG,
} from "../../../Data/DataDashBoard/DashBoardData";
import {
  DashboardDate,
  WeekOptions,
  WeekOptionsForDisplay,
} from "../../../Data/Pages/SelectOptions/DataSelectOptions";
import { useAuthen } from "../../../authen/AuthProvider";
import { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import { Bar, Line } from "react-chartjs-2";
import {
  DashboardQueryKey,
  getAllDashBoard,
} from "../../../services/apiDashboard";
import SelectBannerFilter from "../../../hooks/useBannerFilter/SelectBannerFilter";
import SelectStoreFilter from "../../../hooks/useStoreFilter/SelectStoreFilter";
import { useQuery } from "react-query";
import LoadingSkeletonDashboard from "../../../Layouts/LoadingSkeletonDashboard/LoadingSkeletonDashboard";
import LoadingSkeletonDashboardSmall from "../../../Layouts/LoadingSkeletonDashboardSmall/LoadingSkeletonDashboardSmall";
import { numberWithCommas } from "../../Global/converse-comma";
import "moment-timezone";
import { moneyFormat } from "../../../utils/global";
import CheckUserPending from "../../../utils/CheckUserPending";
import icoDashboardGmv from "../../../assets/images/icons/svg/ico-dashboard-gmv.svg";
import icoDashboardCredit from "../../../assets/images/icons/svg/ico-dashboard-credit.svg";
import icoDashboardComplete from "../../../assets/images/icons/svg/ico-dashboard-complete.svg";
import icoDashboardDelivery from "../../../assets/images/icons/svg/ico-dashboard-delivery.svg";

import icoDashboardArrowUpGreen from "../../../assets/images/icons/svg/ico-arrow-up-money-green.svg";
import icoDashboardArrowDownRed from "../../../assets/images/icons/svg/ico-arrow-down-money-red.svg";

interface IChartDataPoint {
  label: string;
  value: number;
}

function Dashboard() {
  // hook
  const { userInfo } = useAuthen();
  const [rawData, setRawData]: any = useState();
  const [gmvData, setGMVData]: any = useState(EmptyData);
  const [completeData, setCompleteData]: any = useState(EmptyData);
  const [creditData, setCreditData]: any = useState(EmptyData);
  const [bannerData, setBannerData]: any = useState(EmptyData);
  const [totalGMV, setTotalGMV] = useState("");
  const [dateCompare, setDateCompare]: any = useState();
  const [totalCredit, setTotalCredit] = useState("");
  const [totalComplete, setTotalComplete] = useState(0);
  const [totalDelivery, setTotalDelivery] = useState(0);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const initialValue = new Date();
  const initialValueWeek = new Date();

  const dateStartPage = moment(initialValueWeek)
    .startOf("weeks")
    .add(1, "day")
    .toDate();

  initialValueWeek.setDate(initialValueWeek.getDate() - 7);

  const [diffGMV, setDiffGMV] = useState(0);
  const [diffCredit, setDiffCredit] = useState(0);
  const [diffComplete, setDiffComplete] = useState(0);
  const [diffDelivery, setDiffDelivery] = useState(0);

  const [dataCalendarTo, setDateTo] = useState(initialValue);
  const [dataCalendarFrom, setDateFrom] = useState(dateStartPage);

  const [dateRange, setDateRange]: any = useState(WeekOptions[0]);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const [filterOptions, setFilterOptions]: any = useState({
    from_date: moment(dataCalendarFrom)
      ?.set({ hours: 0, minute: 0, second: 0 })
      .format("YYYY-MM-DD HH:mm:ss"),
    to_date: moment(dataCalendarTo)
      ?.set({ hours: 23, minute: 59, second: 59 })
      .format("YYYY-MM-DD HH:mm:ss"),
    store: "",
    brand: "",
    store_label: "",
    brand_label: "",
    store_group: "",
    product_name: "",
    time_zone: timeZone,
  });

  const {
    data: dashboardData,
    status: dashboardDataStatus,
    isFetching,
  } = useQuery<any>([DashboardQueryKey?.getAllDashBoard], () =>
    getAllDashBoard(filterOptions)
  );

  useEffect(() => {
    if (dashboardData) {
      onUpdateData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Set the initial value
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onUpdateData = async () => {
    setLoadingDashboard(true);
    if (dashboardDataStatus !== "loading" && dashboardDataStatus !== "error") {
      CalculateDashboardData(filterOptions, dashboardData?.data);
    } else {
      setLoadingDashboard(false);
    }
    setLoadingDashboard(false);
  };

  const transFormerChartData = (
    fromDate: string,
    toDate: string,
    data: IChartDataPoint[]
  ) => {
    const newChartData: IChartDataPoint[] = [];
    const datesInRange: string[] = [];

    const startDate: Date = moment(fromDate).tz(timeZone).toDate();
    const endDate: Date = moment(toDate).tz(timeZone).toDate();
    let currentDate: Date = new Date(startDate);

    while (currentDate <= endDate) {
      const day: string = currentDate.getDate().toString().padStart(2, "0");
      const month: string = currentDate.toLocaleString("default", {
        month: "short",
      });
      const year = currentDate.getFullYear().toString().slice(-2);

      const dateString: string = `${day} ${month} ${year}`;
      datesInRange?.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    datesInRange?.forEach((date) => {
      if (!data?.some((chart: any) => chart?.label === date)) {
        const emptyObj: IChartDataPoint = { label: date, value: 0 };
        newChartData?.push(emptyObj);
      } else {
        const existObj: IChartDataPoint = data?.find(
          (d: any) => d?.label === date
        ) ?? { label: date, value: 0 };
        newChartData?.push(existObj);
      }
    });

    const res = newChartData?.map((obj: IChartDataPoint) => {
      return {
        label: obj?.label,
        value: obj?.value,
      };
    });

    return res;
  };

  const calculateDisplayChart = (data: any) => {
    const numPoints = data?.length;

    if (numPoints < 31) {
      return data?.map((item: any) => ({
        label: `${item?.label}`,
        value: item?.value,
      }));
    } else if (numPoints < 61) {
      // Group by week
      const numWeeks = Math.ceil(numPoints / 7);
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numWeeks; i++) {
        const weekLabel = `Week ${i + 1}`;
        valueSum = 0;

        // Calculate the sum of values for the current week
        for (let j = i * 7; j < Math.min((i + 1) * 7, numPoints); j++) {
          const item = data[j];
          valueSum += item?.value;
        }

        newData?.push({ label: weekLabel, value: valueSum });
      }

      return newData;
    } else if (numPoints < 91) {
      // Group by week (8 weeks)
      const numWeeks = Math.ceil(numPoints / 7);
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numWeeks; i++) {
        const weekLabel = `Week ${i + 1}`;
        valueSum = 0;

        // Calculate the sum of values for the current week
        for (let j = i * 7; j < Math.min((i + 1) * 7, numPoints); j++) {
          const item = data[j];
          valueSum += item?.value;
        }

        newData?.push({ label: weekLabel, value: valueSum });
      }

      return newData;
    } else if (numPoints < 121) {
      // Group by week (12 weeks)
      const numWeeks = Math.ceil(numPoints / 7);
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numWeeks; i++) {
        const weekLabel = `Week ${i + 1}`;
        valueSum = 0;

        // Calculate the sum of values for the current week
        for (let j = i * 7; j < Math.min((i + 1) * 7, numPoints); j++) {
          const item = data[j];
          valueSum += item?.value;
        }

        newData.push({ label: weekLabel, value: valueSum });
      }

      return newData;
    } else if (numPoints < 366) {
      // Group by month
      let currentMonthLabel = null;
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numPoints; i++) {
        const item = data[i];
        const monthLabel = item?.label.slice(3, 9); // Assuming the label is in the format "01 Jan 23"

        if (currentMonthLabel !== monthLabel) {
          if (currentMonthLabel !== null) {
            newData.push({ label: currentMonthLabel, value: valueSum });
          }
          currentMonthLabel = monthLabel;
          valueSum = item?.value; // Start with the current item's value
        } else {
          valueSum += item?.value; // Add the item's value to the current month's sum
        }

        if (i === numPoints - 1) {
          newData.push({ label: currentMonthLabel, value: valueSum });
        }
      }

      return newData;
    } else {
      // Group by year
      let currentYearLabel = null;
      let valueSum = 0;
      const newData = [];

      for (let i = 0; i < numPoints; i++) {
        const item = data[i];
        const yearLabel = item?.label.slice(-2); // Assuming the label is in the format "01 Jan 23"

        if (currentYearLabel !== yearLabel) {
          if (currentYearLabel !== null) {
            newData.push({ label: `20${currentYearLabel}`, value: valueSum });
          }
          currentYearLabel = yearLabel;
          valueSum = item?.value; // Start with the current item's value
        } else {
          valueSum += item?.value; // Add the item's value to the current year's sum
        }

        if (i === numPoints - 1) {
          newData.push({ label: `20${currentYearLabel}`, value: valueSum });
        }
      }

      return newData;
    }
  };

  // 1. GMV
  const calculateGmvChart = (fromDate: string, toDate: string, data: any) => {
    const gmvChart: IChartDataPoint[] =
      data?.data_gmv?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
      })) || [];

    const newChartData = transFormerChartData(fromDate, toDate, gmvChart);
    const transformData = calculateDisplayChart(newChartData);

    const gmvData = {
      labels: transformData?.map((data: any) => data?.label),
      datasets: [
        {
          label: "GMV",
          data: transformData?.map((data: any) => data?.value),
          borderColor: "#61B8E7",
          backgroundColor: "#61B8E7",
          tension: 0.3,
          borderWidth: 3,
          pointRadius: 0,
        },
      ],
    };
    setGMVData(gmvData);
  };

  // 2. Complete Visit
  const calculateCompleteDataChart = (
    fromDate: string,
    toDate: string,
    data: any
  ) => {
    const completeChart: IChartDataPoint[] =
      data?.data_completed_visits?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
      })) || [];

    const newChartData = transFormerChartData(fromDate, toDate, completeChart);
    const transformData = calculateDisplayChart(newChartData);

    const completeData = {
      labels: transformData?.map((data: any) => data?.label),
      datasets: [
        {
          label: "Credit Visit",
          data: transformData?.map((data: any) => data?.value),
          tension: 0.3,
          borderWidth: 3,
          pointRadius: 3,
          borderColor: "#99D3F2",
          backgroundColor: "#FFF",
          hoverBackgroundColor: "#FFF",
          hoverBorderColor: "#61B8E7",
          hoverBorderWidth: 3,
        },
      ],
    };
    setCompleteData(completeData);
  };

  // 3. Credit
  const calculateCreditChart = (
    fromDate: string,
    toDate: string,
    data: any
  ) => {
    const creditChart: IChartDataPoint[] =
      data?.data_credit?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
      })) || [];

    const newChartData = transFormerChartData(fromDate, toDate, creditChart);
    const transformData = calculateDisplayChart(newChartData);

    const creditData = {
      labels: transformData?.map((data: any) => data?.label),
      datasets: [
        {
          label: "Credits",
          data: transformData?.map((data: any) => data?.value),
          borderRadius: 18,
          backgroundColor: "#FBF0CC",
          hoverBackgroundColor: "#F8B771",
        },
      ],
    };

    setCreditData(creditData);
  };

  // 4. Delivery
  const calculateBrandDeliveryChart = (
    fromDate: string,
    toDate: string,
    data: any
  ) => {
    const brandChart: IChartDataPoint[] =
      data?.data_delivery?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
      })) || [];

    const brandRow = {
      labels: brandChart?.map((data: any) => data?.label),
      datasets: [
        {
          label: "Banner Delivery",
          data: brandChart?.map((data: any) => data?.value),
          borderRadius: 18,
          backgroundColor: "#E3F5FB",
          hoverBackgroundColor: "#BDE4F5",
        },
      ],
    };

    setBannerData(brandRow);
  };

  const CalculateDashboardData = (filterParam: any, data: any) => {
    setRawData(data);
    const gmvTotal = numberWithCommas(data?.total_gmv);
    const credits = numberWithCommas(data?.total_credit);
    const diffGMV = numberWithCommas(data?.week?.totalGMV2Week);
    const diffCredit = numberWithCommas(data?.week?.totalCredit2Week);

    const filterFromDate: string = filterParam?.from_date;
    const filterToDate: string = filterParam?.to_date;

    setDateCompare({
      dateFrom: data?.compare_form_date,
      dateTo: data?.compare_to_date,
    });
    setTotalGMV(gmvTotal);
    setTotalCredit(credits);
    setTotalComplete(data?.total_completed_visits);
    setTotalDelivery(data?.total_delivery);
    setDiffGMV(diffGMV);
    setDiffCredit(diffCredit);
    setDiffComplete(data?.week?.totalCompletedVisits2Week);
    setDiffDelivery(data?.week?.totalDelivery2Week);

    // GMV
    calculateGmvChart(filterFromDate, filterToDate, data);

    // Complete Data
    calculateCompleteDataChart(filterFromDate, filterToDate, data);

    // Credit
    calculateCreditChart(filterFromDate, filterToDate, data);

    // Brand Delivery
    calculateBrandDeliveryChart(filterFromDate, filterToDate, data);

    setLoadingDashboard(false);
  };

  const getDashboardWithFilter = async (filterParam: any) => {
    setLoadingDashboard(true);
    await getAllDashBoard(filterParam).then((response) => {
      CalculateDashboardData(filterParam, response?.data);
      setLoadingDashboard(false);
    });
  };

  const transformDataUpdateFilter: any = () => {
    const {
      from_date: nFromDate,
      to_date: nToDate,
      store: nStore,
      brand: nBrand,
      product_name: nProductName,
      store_group: nStoreGroup,
      time_zone: nTimeZone,
    } = filterOptions;

    const newObj = {
      from_date: nFromDate,
      to_date: nToDate,
      store: nStore?.value ?? "",
      brand: nBrand?.value ?? "",
      product_name: nProductName?.value ?? "",
      store_group: nStoreGroup?.value ?? "",
      time_zone: nTimeZone,
    };

    return newObj;
  };

  const onSelectStore = async (data: any) => {
    setFilterOptions({ ...filterOptions, store: data });

    const newDataFilter = transformDataUpdateFilter();
    getDashboardWithFilter({
      ...newDataFilter,
      store: data?.value ? data?.value : "",
    });
  };

  const onSelectBanner = async (data: any) => {
    setFilterOptions({ ...filterOptions, banner: data });

    const newDataFilter = transformDataUpdateFilter();
    getDashboardWithFilter({
      ...newDataFilter,
      banner: data?.value ? data?.value : "",
    });
  };

  const onSelectDateTo = async (date: any) => {
    const selectedDate = moment(date, "YYYY-MM-DD").toDate();
    setDateRange(WeekOptions[4]);
    setDateTo(selectedDate);
    setFilterOptions({
      ...filterOptions,
      to_date: date
        ? moment(date)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss")
        : "",
    });

    const newDataFilter = transformDataUpdateFilter();
    getDashboardWithFilter({
      ...newDataFilter,
      to_date: date
        ? moment(date)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss")
        : "",
    });
  };

  const onSelectDateFrom = async (date: any) => {
    const selectedDate = moment(date, "YYYY-MM-DD").toDate();
    setDateRange(WeekOptions[4]);
    setDateFrom(selectedDate);
    setFilterOptions({
      ...filterOptions,
      from_date: date
        ? moment(date)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss")
        : "",
    });

    const newDataFilter = transformDataUpdateFilter();
    getDashboardWithFilter({
      ...newDataFilter,
      from_date: date
        ? moment(date)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss")
        : "",
    });
  };

  const onClickSvg = () => {
    const dataFilterRefresh = {
      from_date: filterOptions?.from_date,
      to_date: filterOptions?.to_date,
      store: filterOptions?.store?.value,
      brand: filterOptions?.brand?.value,
      product_name: filterOptions?.product_name?.value,
      store_group: filterOptions?.store_group?.value,
    };
    getDashboardWithFilter(dataFilterRefresh);
  };

  const onSelectDateRange = async (data: any) => {
    let newDateFrom = new Date();
    let newDateTo = new Date();
    setDateRange(data);
    switch (data?.value) {
      case 0:
        newDateFrom = moment(newDateFrom)
          .startOf("weeks")
          .add(1, "day")
          .toDate();
        setDateFrom(newDateFrom);
        setDateTo(newDateTo);
        setFilterOptions({
          ...filterOptions,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss"),
        });

        const newDataFilterCase1 = transformDataUpdateFilter();
        getDashboardWithFilter({
          ...newDataFilterCase1,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss"),
        });
        break;
      case -1:
        newDateFrom = moment(newDateFrom)
          .subtract(1, "weeks")
          .startOf("weeks")
          .add(1, "day")
          .toDate();
        newDateTo = moment(newDateTo)
          .subtract(1, "weeks")
          .endOf("weeks")
          .add(1, "day")
          .toDate();
        setDateFrom(newDateFrom);
        setDateTo(newDateTo);
        setFilterOptions({
          ...filterOptions,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss"),
        });

        const newDataFilterCase2 = transformDataUpdateFilter();
        getDashboardWithFilter({
          ...newDataFilterCase2,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss"),
        });
        break;
      case -2:
        newDateFrom = moment(newDateFrom)
          .subtract(2, "weeks")
          .startOf("weeks")
          .add(1, "day")
          .toDate();
        newDateTo = moment(newDateTo)
          .subtract(1, "weeks")
          .endOf("weeks")
          .add(1, "day")
          .toDate();
        setDateFrom(newDateFrom);
        setDateTo(newDateTo);
        setFilterOptions({
          ...filterOptions,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss"),
        });

        const newDataFilterCase3 = transformDataUpdateFilter();
        getDashboardWithFilter({
          ...newDataFilterCase3,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss"),
        });
        break;
      case 1:
        newDateFrom = moment(newDateFrom).startOf("month").toDate();
        setDateFrom(newDateFrom);
        setDateTo(newDateTo);
        setFilterOptions({
          ...filterOptions,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_date: moment(newDateTo)
            ?.set({ hour: 23, minute: 59, second: 59 })
            .format("YYYY-MM-DD HH:mm:ss"),
        });

        const newDataFilterCase4 = transformDataUpdateFilter();
        getDashboardWithFilter({
          ...newDataFilterCase4,
          from_date: moment(newDateFrom)
            ?.set({ hour: 0, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_date: moment(newDateTo)
            .set({ hour: 23, minute: 59, second: 59 })
            ?.format("YYYY-MM-DD HH:mm:ss"),
        });
        break;
      default:
        break;
    }
  };

  const TimeReset = () => {
    const [timeReset, setTimeReset] = useState({ hour: 0, min: 0, sec: 0 });
    useEffect(() => {
      setTimeReset({ hour: 0, min: 0, sec: 0 });
      let dateOne = moment(rawData?.ckeck_since);
      const interval = setInterval(() => {
        let dateTwo = moment();
        let resultSecond = dateTwo.diff(dateOne, "second");
        let resultMinute = dateTwo.diff(dateOne, "minute");
        let resultHour = dateTwo.diff(dateOne, "hour");
        setTimeReset({
          hour: resultHour,
          min: resultMinute,
          sec: resultSecond,
        });
      }, 5000);
      return () => clearInterval(interval);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawData]);
    return (
      <span className="time-data" id="ct">
        {timeReset.sec > 60
          ? timeReset.min > 60
            ? timeReset.hour + "hours ago"
            : timeReset.min + " minutes ago"
          : timeReset.sec + " Seconds ago"}
      </span>
    );
  };

  const PageTop = () => {
    return (
      <>
        <Row>
          <Col lg={6} md={6} xl={6} sm={12}>
            <div className="welcome-text-block">
              <h1>Hi, {userInfo?.cpg_brand?.cpg_brand_name}</h1>
            </div>
          </Col>
          <Col lg={6} md={6} xl={6} sm={12}></Col>
        </Row>

        <Row>
          <Col lg={6} md={6} xl={6} sm={12}>
            <div className="welcome-text-block">
              <p>Let's see how business is going.</p>
            </div>
          </Col>
          <Col lg={6} md={6} xl={6} sm={12}>
            <div className="last-data-refresh">
              <span className="last-data-label">Last data refresh :</span>
              <TimeReset />
              <TIME_SVG onClickEvent={onClickSvg} />
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const FilterDateScreenDesktop = () => {
    return (
      <>
        <Col xl={6} sm={12} className="mb-3">
          <div className="field-set select-single-value">
            <span className="head-field-set">Date</span>
            <Form.Group>
              <InputGroup>
                {/* Left */}
                <Select
                  className="select-filter-items select-filter-items-date custom-inner"
                  defaultValue={WeekOptions[0]}
                  value={dateRange}
                  isSearchable={false}
                  options={WeekOptions}
                  onChange={onSelectDateRange}
                  classNamePrefix="Select"
                />

                {/* Right */}
                <Form.Group className="d-flex bg-white wrapper_form_filter_date_time gap-2">
                  <DashboardDate
                    loadingDashboard={loadingDashboard}
                    setDataCalendar={onSelectDateFrom}
                    dataCalendar={dataCalendarFrom}
                  />
                  <DashboardDate
                    loadingDashboard={loadingDashboard}
                    setDataCalendar={onSelectDateTo}
                    dataCalendar={dataCalendarTo}
                  />
                </Form.Group>
              </InputGroup>
            </Form.Group>
          </div>
        </Col>
      </>
    );
  };

  const FilterDateScreenMobile = () => {
    return (
      <>
        <Col xl={6} sm={12} className="mb-3">
          <div className="field-set select-single-value">
            <span className="head-field-set">Date</span>
            <Select
              className="select-filter-items custom-inner"
              defaultValue={WeekOptions[0]}
              value={dateRange}
              isSearchable={false}
              options={WeekOptions}
              onChange={onSelectDateRange}
              classNamePrefix="Select"
            />
          </div>
        </Col>
        <Col xl={6} sm={12} className="mb-3">
          <div className="field-set select-single-value">
            <span className="head-field-set">Custom Date</span>
            <DashboardDate
              loadingDashboard={loadingDashboard}
              setDataCalendar={onSelectDateFrom}
              dataCalendar={dataCalendarFrom}
            />
            <DashboardDate
              loadingDashboard={loadingDashboard}
              setDataCalendar={onSelectDateTo}
              dataCalendar={dataCalendarTo}
            />
          </div>
        </Col>
      </>
    );
  };

  const PageFilter = () => {
    return (
      <Card.Body className="p-4 ">
        <Row className="row_wrapper_filter">
          {/* Date */}
          {isMobileScreen ? (
            <FilterDateScreenMobile />
          ) : (
            <FilterDateScreenDesktop />
          )}

          {/* Store / Brand */}
          <Col xl={6} sm={12}>
            <Row>
              <Col xl={6} sm={12}>
                <div className="field-set select-single-value">
                  <span className="head-field-set">Store</span>
                  <SelectStoreFilter
                    loading={loadingDashboard}
                    value={filterOptions?.store}
                    onChange={async (data: any) => {
                      onSelectStore(data);
                    }}
                  />
                </div>
              </Col>
              <Col xl={6}>
                <div className="field-set select-single-value">
                  <span className="head-field-set">Banner</span>
                  <SelectBannerFilter
                    loading={loadingDashboard}
                    value={filterOptions?.banner}
                    onChange={async (data: any) => {
                      onSelectBanner(data);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    );
  };

  const GMVChart = () => {
    const chartGmvOptions: any = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
        axis: "xy",
      },
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item: any) =>
              `${item.dataset.label}: $${item.formattedValue}`,
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: false,
            drawBorder: false,
            color: "rgba(119, 119, 142, 0.08)",
          },
          ticks: {
            fontColor: "#b0bac9",
            autoSkip: true,
          },
          scaleLabel: {
            display: false,
            labelString: "Month",
            fontColor: "transparent",
          },
        },
        y: {
          beginAtZero: true,
          display: true,
          ticks: {
            callback: function (value: any) {
              return moneyFormat(value, true);
            },
          },
          grid: {
            display: true,
            drawBorder: false,
            zeroLineColor: "rgba(142, 156, 173,0.1)",
            color: "rgba(142, 156, 173,0.1)",
          },
          scaleLabel: {
            display: false,
            labelString: "sales",
            fontColor: "transparent",
          },
        },
      },
    };
    return (
      <Line
        className="chart-dropshadow"
        style={{ height: "inherit" }}
        options={chartGmvOptions}
        data={gmvData}
      />
    );
  };

  const CompleteChart = () => {
    const chartCompleteOptions: any = {
      chartArea: {
        backgroundColor: "rgba(251, 85, 85, 0.4)",
      },
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
        axis: "xy",
      },
      plugins: {
        tooltip: {
          enabled: true,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: false,
            drawBorder: false,
            color: "rgba(119, 119, 142, 0.08)",
          },
          ticks: {
            fontColor: "#b0bac9",
            autoSkip: true,
          },
          scaleLabel: {
            display: false,
            labelString: "Month",
            fontColor: "transparent",
          },
        },
        y: {
          beginAtZero: true,
          display: true,
          grid: {
            display: true,
            drawBorder: false,
            zeroLineColor: "rgba(142, 156, 173,0.1)",
            color: "rgba(142, 156, 173,0.1)",
          },
          scaleLabel: {
            display: false,
            labelString: "sales",
            fontColor: "transparent",
          },
        },
      },
    };

    return (
      <Line
        className="chart-dropshadow"
        style={{ height: "inherit" }}
        options={chartCompleteOptions}
        data={completeData}
      />
    );
  };

  const CreditBarChart = () => {
    const chartCreditOptions: any = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
        axis: "xy",
      },
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (item: any) =>
              `${item.dataset.label}: $${item.formattedValue}`,
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: false,
            drawBorder: false,
            color: "rgba(119, 119, 142, 0.08)",
          },
          ticks: {
            fontColor: "#b0bac9",
            autoSkip: true,
          },
          scaleLabel: {
            display: false,
            labelString: "Month",
            fontColor: "transparent",
          },
        },
        y: {
          beginAtZero: true,
          display: true,
          ticks: {
            callback: function (value: any) {
              return moneyFormat(value, true);
            },
          },
          grid: {
            display: true,
            drawBorder: false,
            zeroLineColor: "rgba(142, 156, 173,0.1)",
            color: "rgba(142, 156, 173,0.1)",
          },
          scaleLabel: {
            display: false,
            labelString: "sales",
            fontColor: "transparent",
          },
        },
      },
    };

    return (
      <Bar
        className="chart-dropshadow"
        style={{ height: "inherit" }}
        options={chartCreditOptions}
        data={creditData}
      />
    );
  };

  const BrandBarChart = () => {
    return (
      <Bar
        className="chart-dropshadow"
        style={{ height: "inherit" }}
        options={BarChartOptions}
        data={bannerData}
      />
    );
  };

  const checkMoneyMinus = (money: any) => {
    const res: number = parseInt(money);
    if (res < 0) {
      return "money-down-red";
    } else if (res > 0) {
      return "money-up-green";
    } else {
      return "money-default";
    }
  };

  const DisplayArrow = ({ data }: any) => {
    const checkData: number = parseInt(data);
    if (checkData > 0) {
      return (
        <img
          className="img-arrow-color"
          src={icoDashboardArrowUpGreen}
          alt="Brand Portal"
        />
      );
    } else if (checkData < 0) {
      return (
        <img
          className="img-arrow-color"
          src={icoDashboardArrowDownRed}
          alt="Brand Portal"
        />
      );
    } else {
      return <></>;
    }
  };

  const GrabShow = () => {
    let displayDateCompare = "N/A";
    const dataObj = WeekOptionsForDisplay?.find(
      (f: any) => f?.value === dateRange?.value
    );

    if (dataObj) {
      displayDateCompare = dataObj?.label;

      if (dataObj?.value === 2) {
        const compareDateFrom = moment
          .utc(dateCompare?.dateFrom)
          .format("MM/DD/YY");
        const compareDateTo = moment
          .utc(dateCompare?.dateTo)
          .format("MM/DD/YY");
        const strDate = `Since Last ${compareDateFrom} -  ${compareDateTo}`;
        displayDateCompare = strDate;
      }
    }

    return (
      <>
        <Row className="wrap-row-padding">
          {/* Left: Chart */}
          <Col lg={12} md={12} sm={12} xl={9}>
            <Row>
              <Col
                className="wrap-dashboard-card"
                lg={12}
                md={12}
                sm={12}
                xl={6}
              >
                <div className="dashboard-card">
                  <div className="d-flex">
                    <div className="flex-grow-1 d-flex">
                      <h3>GMV</h3>
                    </div>
                  </div>
                  <div className="chart-block">
                    {loadingDashboard || isFetching ? (
                      <LoadingSkeletonDashboard />
                    ) : (
                      <GMVChart />
                    )}
                  </div>
                </div>
              </Col>

              <Col
                className="wrap-dashboard-card"
                lg={12}
                md={12}
                sm={12}
                xl={6}
              >
                <div className="dashboard-card">
                  <div className="d-flex">
                    <div className="flex-grow-1 d-flex">
                      <h3>Credits</h3>
                    </div>
                  </div>
                  <div className="chart-block">
                    {loadingDashboard || isFetching ? (
                      <LoadingSkeletonDashboard />
                    ) : (
                      <CreditBarChart />
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                className="wrap-dashboard-card"
                lg={12}
                md={12}
                sm={12}
                xl={6}
              >
                <div className="dashboard-card">
                  <div className="d-flex">
                    <div className="flex-grow-1 d-flex">
                      <h3>Completed Visits</h3>
                    </div>
                  </div>
                  <div className="chart-block">
                    {loadingDashboard || isFetching ? (
                      <LoadingSkeletonDashboard />
                    ) : (
                      <CompleteChart />
                    )}
                  </div>
                </div>
              </Col>

              <Col
                className="wrap-dashboard-card"
                lg={12}
                md={12}
                sm={12}
                xl={6}
              >
                <div className="dashboard-card">
                  <div className="d-flex">
                    <div className="flex-grow-1 d-flex">
                      <h3>Deliveries By Banner</h3>
                    </div>
                  </div>
                  <div className="chart-block">
                    {loadingDashboard || isFetching ? (
                      <LoadingSkeletonDashboard />
                    ) : (
                      <BrandBarChart />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          {/* Right: Box */}
          <Col
            lg={12}
            md={12}
            sm={12}
            xl={3}
            className="wrap-dashboard-card dashboaord-info-card-section"
          >
            {/* Box: GMV */}
            <div className="info-card gmv-card">
              <div className="d-flex">
                <div className="title-text flex-grow-1">GMV</div>
                <div className="icon-block wrap-img-box">
                  <img src={icoDashboardGmv} alt="Brand Portal" />
                </div>
              </div>

              {loadingDashboard || isFetching ? (
                <LoadingSkeletonDashboardSmall />
              ) : (
                <>
                  <div className="num-block-wrapper d-flex justify-content-between">
                    <div className="num-block">
                      {moneyFormat(totalGMV, true)}
                    </div>
                  </div>

                  <div
                    className={`num-right-block since_last_week text-end ${checkMoneyMinus(
                      diffGMV
                    )}`}
                  >
                    <p>
                      <strong>
                        <span>{moneyFormat(diffGMV, true)}</span>
                        <DisplayArrow data={diffGMV} />
                      </strong>
                    </p>
                  </div>

                  <p className={`since-last text-right`}>
                    {displayDateCompare}
                  </p>
                </>
              )}
            </div>

            {/* Credits */}
            <div className="info-card credits-card">
              <div className="d-flex">
                <div className="title-text flex-grow-1">Credits</div>
                <div className="icon-block wrap-img-box">
                  <img src={icoDashboardCredit} alt="Brand Portal" />
                </div>
              </div>

              {loadingDashboard || isFetching ? (
                <LoadingSkeletonDashboardSmall />
              ) : (
                <>
                  <div className="num-block-wrapper d-flex justify-content-between">
                    <div className="num-block">
                      {moneyFormat(totalCredit, true)}
                    </div>
                  </div>
                  <div
                    className={`num-right-block since_last_week text-end ${checkMoneyMinus(
                      diffCredit
                    )}`}
                  >
                    <p>
                      <strong>
                        <span>{moneyFormat(diffCredit, true)}</span>
                        <DisplayArrow data={diffCredit} />
                      </strong>
                    </p>
                  </div>

                  <p className={`since-last text-right`}>
                    {displayDateCompare}
                  </p>
                </>
              )}
            </div>

            {/* Completed visits */}
            <div className="info-card visits-card">
              <div className="d-flex">
                <div className="title-text flex-grow-1">Completed Visits</div>
                <div className="icon-block wrap-img-box">
                  <img src={icoDashboardComplete} alt="Brand Portal" />
                </div>
              </div>
              {loadingDashboard || isFetching ? (
                <LoadingSkeletonDashboardSmall />
              ) : (
                <>
                  <div className="num-block-wrapper d-flex justify-content-between">
                    <div className="num-block">
                      {moneyFormat(totalComplete, false)}
                    </div>
                  </div>
                  <div
                    className={`num-right-block since_last_week text-end ${checkMoneyMinus(
                      diffComplete
                    )}`}
                  >
                    <p>
                      <strong>
                        <span>{moneyFormat(diffComplete, false)}</span>
                        <DisplayArrow data={diffComplete} />
                      </strong>
                    </p>
                  </div>
                  <p className={`since-last text-right`}>
                    {displayDateCompare}
                  </p>
                </>
              )}
            </div>

            {/* Deliveries */}
            <div className="info-card deliveries-card">
              <div className="d-flex">
                <div className="title-text flex-grow-1">Deliveries</div>
                <div className="icon-block wrap-img-box-small">
                  <img src={icoDashboardDelivery} alt="Brand Portal" />
                </div>
              </div>

              {loadingDashboard || isFetching ? (
                <LoadingSkeletonDashboardSmall />
              ) : (
                <>
                  <div className="num-block-wrapper d-flex justify-content-between">
                    <div className="num-block">
                      {moneyFormat(totalDelivery, false)}
                    </div>
                  </div>
                  <div
                    className={`num-right-block since_last_week text-end ${checkMoneyMinus(
                      diffDelivery
                    )}`}
                  >
                    <p>
                      <strong>
                        <span>{moneyFormat(diffDelivery, false)}</span>
                        <DisplayArrow data={diffDelivery} />
                      </strong>
                    </p>
                  </div>
                  <p className={`since-last text-right`}>
                    {displayDateCompare}
                  </p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <CheckUserPending />
      <div className={styles.Dashboard}>
        <div className="dashboard-page">
          <Row className="wrap-row-padding">
            <Col xl={12}>
              <Card className="p-0 wrap-header-menu">
                <Card.Body className="p-4 wrap-header-menu-top">
                  <PageTop />
                </Card.Body>

                <PageFilter />
              </Card>
            </Col>
          </Row>
          <div className="dashboard-section-wrapper">
            <div className="dashboard-section flex-grow-1">
              <GrabShow />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

export const EmptyData = {
  labels: [],
  datasets: [
    {
      label: "Loading Data",
      data: [],
      borderColor: "#05c3fb",
      backgroundColor: "#05c3fb",
      tension: 0.3,
      borderWidth: 3,
      pointRadius: 0,
    },
  ],
};
