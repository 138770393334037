export const DEFAULT_BASE_URL = "";
export const DEFAULT_BASE_API_LOCAL = "http://localhost:3000/api/v1";
export const DEFAULT_BASE_API =
  process.env.REACT_APP_API_URL || "http://localhost:3000/api/v1";

export const TOKEN = "brand-portal";
export const INPUT_MIN_LENGTH = 4;
export const INPUT_MAX_LENGTH = 120;
export const MIN_FILE_SIZE_BYTES = 153600;
export const MAX_FILE_SIZE_BYTES = 8192000;

export const STATUS_LOADING = "loading";
export const STATUS_ERROR = "error";

// Columns: Product
export const COLUMNS_PRODUCT = [
  {
    Header: "IMAGE",
    accessor: "IMAGE",
    className: "text-center ",
    disableSortBy: true,
  },
  {
    Header: "PRODUCT CODE",
    accessor: "PRODUCT_CODE",
    className: "left-center ",
  },
  {
    Header: "UPC",
    accessor: "UPC",
    className: "left-center ",
  },
  {
    Header: "PRODUCT NAME",
    accessor: "PRODUCT_NAME",
    className: "left-center ",
  },
  {
    Header: "STATUS",
    accessor: "STATUS",
    className: "text-center ",
  },
  {
    Header: "VIEW",
    accessor: "VIEW",
    className: "text-center ",
  },
  {
    Header: "ACTION",
    accessor: "ACTION",
    className: "text-center ",
  },
];

export const COLUMNS_PRICE_PROMOTION = [
  {
    Header: "NAME",
    accessor: "NAME",
    className: "text-center",
  },
  {
    Header: "FROM",
    accessor: "FROM",
    className: "text-center",
  },
  {
    Header: "STORE STATUS",
    accessor: "STORE_STATUS",
    className: "text-center ",
  },
  {
    Header: "PROMOTION",
    accessor: "PROMOTION_PRICE",
    className: "text-center promo-price-col",
    cellClassName: "text-right",
  },
  {
    Header: "PROMOTION FROM",
    accessor: "PROMOTION_FROM",
    className: "text-center ",
  },
  {
    Header: "PROMOTION TO",
    accessor: "PROMOTION_TO",
    className: "text-center ",
  },
];

export const COLUMNS_PRICE = [
  {
    Header: "NAME",
    accessor: "NAME",
    className: "text-center",
  },
  {
    Header: "FROM",
    accessor: "FROM",
    className: "text-center",
  },
  {
    Header: "STORE STATUS",
    accessor: "STORE_STATUS",
    className: "text-center base-price-col",
  },
];

export const COLUMNS_PRICE_STORE_PROMOTION = [
  {
    Header: "NAME",
    accessor: "NAME",
    className: "text-center",
  },
  {
    Header: "FROM",
    accessor: "FROM",
    className: "text-center",
  },
  {
    Header: "STORE STATUS",
    accessor: "STORE_STATUS",
    className: "text-center",
  },
  {
    Header: "PROMOTION",
    accessor: "PROMOTION_PRICE",
    className: "text-center promo-price-col",
    cellClassName: "text-right",
  },
  {
    Header: "PROMOTION FROM",
    accessor: "PROMOTION_FROM",
    className: "text-center",
  },
  {
    Header: "PROMOTION TO",
    accessor: "PROMOTION_TO",
    className: "text-center",
  },
];

export const COLUMNS_PRICE_STORE = [
  {
    Header: "NAME",
    accessor: "NAME",
    className: "text-center",
  },
  {
    Header: "FROM",
    accessor: "FROM",
    className: "text-center",
  },
  {
    Header: "STORE STATUS",
    accessor: "STORE_STATUS",
    className: "text-center base-price-col",
    cellClassName: "text-right",
  },
  {
    Header: "PROMOTION",
    accessor: "PROMOTION_PRICE",
    className: "text-center promo-price-col",
    cellClassName: "text-right",
  },
];

// Columns: Activity
export const COLUMNS_ACTIVITY = [
  {
    Header: "ACTIVITY",
    accessor: "ACTIVITY",
    className: "text-left w-15",
    expandedContent: (rowData: any) => rowData,
  },
  {
    Header: "RETAILER",
    accessor: "RETAILER",
    className: "text-left w-10",
  },
  {
    Header: "CHECK IN/OUT",
    accessor: "CHECK_IN_OUT",
    className: "text-left w-15",
  },
  {
    Header: "GMV",
    accessor: "GMV",
    className: "text-left wr-8",
    disableSortBy: true,
  },
  {
    Header: "",
    accessor: "Sku",
    className: "wr-20",
    disableSortBy: true,
  },
  {
    Header: "",
    accessor: "before",
    className: "wr-20",
    disableSortBy: true,
  },
];

export const COLUMNS_ACTIVITY_PRODUCT = [
  {
    Header: "Product Name",
    accessor: "PRODUCT_NAME",
    className: "w-25",
  },
  {
    Header: "Stock",
    accessor: "STOCK",
    className: "w-5",
  },
  {
    Header: "Credits",
    accessor: "CREDITS",
    className: "w-5",
  },
  {
    Header: "Delivery",
    accessor: "DELIVERY",
    className: "w-8",
  },
  {
    Header: "GMV",
    accessor: "GMV",
    className: "wr-6",
  },
  {
    Header: "UPC",
    accessor: "SKU",
    className: "wr-37",
  },
];
