import { Navigate, useLocation } from "react-router-dom";
import { useAuthen } from "../authen/AuthProvider";

const CheckUserPending = () => {
  const { userInfo } = useAuthen();
  const location = useLocation();

  const statusBrand = userInfo?.cpg_brand?.cpg_brand_is_active.toLowerCase();
  if (statusBrand === "pending") {
    if (
      location.pathname === "/dashboard/" ||
      location.pathname === "/activity/" ||
      location.pathname === "/field-order-report/" ||
      location.pathname === "/stock-audit-report/"
    ) {
      return <Navigate to="/no-permission" />;
    }
    return <Navigate to="/profile" />;
  }
  return <></>;
};
export default CheckUserPending;
