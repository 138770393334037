import ServerResponse from "../models/ServerResponse";
import axiosClient, { axiosFromData } from "./baseApi";

export const bannerQueryKey: any = {
  getAllBanner: "getAllBanner",
  getBannerFilter: "getBannerFilter",
};

export const getAll = async () => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>("/banner/all");
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const GetById = async (id: any) => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>("/profile/one/", id);
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const updateBanner = async (data: any) => {
  try {
    const res: any = await axiosFromData.post<ServerResponse<any>>(
      `/banner/update`,
      data
    );
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getBannerFilter = async () => {
  try {
    const res: any = await axiosFromData.get<ServerResponse<any>>(
      "banner/getFilter"
    );
    return await Promise.resolve(res.data);
  } catch (error) {
    return await Promise.reject(error);
  }
};
