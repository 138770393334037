import { AxiosResponse } from "axios";
import axiosClient, { axiosFromData } from "./baseApi";

export const usersQueryKey: any = {
  getCurrentUser: "getCurrentUser",
  getAllGetAll: "getAllUsers",
  getFilterGetAllKey: "getFilterGetAllKey",
  getUserTokenKey: "tokenActivation",
};

interface registerBrandType {
  company_name: string;
  company_website: string;
  name: string;
  phone: string;
  email: string;
  company_type: string;
}

export const getCurrentUser = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>("/profile/current");
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const updateProfile = async (data: any) => {
  try {
    const res: any = await axiosClient.put<AxiosResponse<any>>(
      `/profile/update`,
      data
    );
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const updateProfileForm = async (data: any) => {
  try {
    const res: any = await axiosFromData.post<AxiosResponse<any>>(
      `/profile/update`,
      data
    );
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getUser = async (token: any, userId: any) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      `profile/one/${userId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return await Promise.resolve(res?.data?.data);
  } catch (err) {
    console.error(err);
    return await Promise.reject(err);
  }
};

export const getAll = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>("/users/all");
    return await Promise.resolve(res.data);
  } catch (err) {
    console.error(err);
    return await Promise.reject(err);
  }
};

export const getByEmail = async (email: string) => {
  try {
    const x = await axiosClient.get<AxiosResponse<any>>(`/user/${email}/get`);
    return await Promise.resolve(x.data.data);
  } catch (x_1) {
    return await Promise.reject(x_1);
  }
};

export const currentUserById = async (userId: any) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      `profile/one/${userId}`
    );
    return await Promise.resolve(res.data.data);
  } catch (x_1) {
    return await Promise.reject(x_1);
  }
};

export const currentUser = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(`profile/current`);
    return await Promise.resolve(res.data.data);
  } catch (x_1) {
    return await Promise.reject(x_1);
  }
};

export const currentUser2 = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(`/user/info`);
    return await Promise.resolve(res.data.data);
  } catch (x_1) {
    return await Promise.reject(x_1);
  }
};

export const getAllFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>("/users/getFilter");
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const registerBrand = async (data: registerBrandType) => {
  try {
    const res = await axiosClient.post<AxiosResponse<any>>(
      "/users/sign-up",
      data
    );
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const tokenActivation = async (token: string) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      `/users/activation/${token}`
    );
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const updateActivation = async (data: any) => {
  try {
    return await axiosClient.post<AxiosResponse<any>>(
      "/users/activation/update",
      data
    );
  } catch (err) {
    return await Promise.reject(err);
  }
};
