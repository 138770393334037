import axios from "axios";
import { TOKEN, DEFAULT_BASE_API } from "../utils/constants";

// create instance called instance
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || DEFAULT_BASE_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
  },
  data: {},
  withCredentials: true,
});

export const axiosFromData = axios.create({
  baseURL: process.env.REACT_APP_API_URL || DEFAULT_BASE_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
  },
  data: {},
});

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    config.headers!["Authorization"] = "Bearer " + token;
  }

  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const res = error.response;
    if (res.status === 401) {
      localStorage.removeItem(TOKEN);
      return Promise.reject(res);
    } else if (res?.status === 403) {
      localStorage.removeItem(TOKEN);
      window.location.href = "/login";
    }

    // remove
    return Promise.reject(error);
  }
);

axiosFromData.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    if (res.status === 401) {
      localStorage.removeItem(TOKEN);
      return Promise.reject(res);
    } else if (res?.status === 403) {
      localStorage.removeItem(TOKEN);
      window.location.href = "/login";
    }
    // console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

export const setAxiosAuthToken = (token: string) => {
  axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axiosFromData.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default axiosClient;
