import React, { Fragment, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./Layouts/Loader/Loader";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import AuthenProvider, { RequireAuthen } from "./authen/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = lazy(() => import("./components/app"));
const Dashboard = lazy(() => import("./components/pages/Dashboard/Dashboard"));
const Error401 = lazy(
  () => import("./components/Authentication/errorPage/Error401/Error401")
);
const Error403 = lazy(
  () => import("./components/Authentication/errorPage/Error403/Error403")
);
const Error404 = lazy(
  () => import("./components/Authentication/errorPage/Error404/Error404")
);
const Error503 = lazy(
  () => import("./components/Authentication/errorPage/Error503/Error503")
);
const NoPermission = lazy(
  () =>
    import("./components/Authentication/errorPage/NoPermissions/NoPermissions")
);
const TokenExpired = lazy(
  () =>
    import("./components/Authentication/errorPage/TokenExpired/TokenExpired")
);
const Error400 = lazy(
  () => import("./components/Authentication/errorPage/Error400/Error400")
);
const Error500 = lazy(
  () => import("./components/Authentication/errorPage/Error500/Error500")
);
const ForgotPassword = lazy(
  () => import("./components/Authentication/Forgot Password/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("./components/Authentication/Reset Password/ResetPassword")
);
const Login = lazy(() => import("./components/Authentication/Login/Login"));
const SignUp = lazy(() => import("./components/Authentication/SignUp/SignUp"));
const Thankyou = lazy(
  () => import("./components/Authentication/SignUp/Thankyou")
);
const AuthenticationPage = lazy(
  () => import("./components/AuthenticationPage")
);
const ErrorPages = lazy(() => import("./components/ErrorPages"));
const SwitcherApp = lazy(() => import("./components/Switcherapp"));
const Products = lazy(() => import("./components/pages/Product/Product"));
const ProductsAdd = lazy(() => import("./components/pages/Product/ProductAdd"));
const ProductsEdit = lazy(
  () => import("./components/pages/Product/ProductEdit")
);
const StockAudit = lazy(
  () => import("./components/pages/StockAudit/StockAudit")
);
const FieldOrder = lazy(
  () => import("./components/pages/FieldOrder/FieldOrder")
);
const Profile = lazy(() => import("./components/pages/Profile/Profile"));
const Activity = lazy(() => import("./components/pages/Activity/Activity"));
const VerifyPage = lazy(
  () => import("./components/Authentication/Verify/Verify")
);
// Custom Request Product
const CustomerRequestProductAdd = lazy(
  () => import("./components/pages/CustomerRequest/Product/ListAdd")
);
const CustomerRequestProductEdit = lazy(
  () => import("./components/pages/CustomerRequest/Product/ListEdit")
);

// Custom Request Price
const CustomerRequestPriceAdd = lazy(
  () => import("./components/pages/CustomerRequest/Price/ListAdd")
);
const CustomerRequestPriceEdit = lazy(
  () => import("./components/pages/CustomerRequest/Price/ListEdit")
);

const ChangeLog = lazy(() => import("./components/pages/ChangeLog/changeLog"));

const container: HTMLElement | any = document.getElementById("root");
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
      refetchOnWindowFocus: false,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
}).then();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Fragment>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            <AuthenProvider>
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <RequireAuthen>
                      <App />
                    </RequireAuthen>
                  }
                >
                  <Route path={"/"} element={<Dashboard />} />
                  <Route path={"/dashboard"} element={<Dashboard />} />

                  {/* Product */}
                  <Route path={"/products"} element={<Products />} />
                  <Route path={"/products/add"} element={<ProductsAdd />} />
                  <Route path={"/products/edit"} element={<ProductsEdit />} />

                  {/* Report */}
                  <Route
                    path={"/stock-audit-report"}
                    element={<StockAudit />}
                  />
                  <Route
                    path={"/field-order-report/"}
                    element={<FieldOrder />}
                  />

                  <Route path={"/profile"} element={<Profile />} />

                  <Route path={"/activity"} element={<Activity />} />

                  {/* Custom Requests: Product */}
                  <Route
                    path={"/customer-request/add-products"}
                    element={<CustomerRequestProductAdd />}
                  />
                  <Route
                    path={"/customer-request/edit-products"}
                    element={<CustomerRequestProductEdit />}
                  />

                  {/* Custom Requests: Price */}
                  <Route
                    path={"/customer-request/add-prices"}
                    element={<CustomerRequestPriceAdd />}
                  />
                  <Route
                    path={"/customer-request/edit-prices"}
                    element={<CustomerRequestPriceEdit />}
                  />
                </Route>

                {/* Authentication Pages */}
                <Route path={"/"} element={<AuthenticationPage />}>
                  <Route path={"login"} element={<Login />} />
                  <Route
                    path={"forgot-password"}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={"reset-password/:token"}
                    element={<ResetPassword />}
                  />
                  <Route path={"activation/:token"} element={<VerifyPage />} />
                </Route>
                <Route path={"/sign-up"} element={<SignUp />} />
                <Route path={"thankyou"} element={<Thankyou />} />
                <Route path={"/changelog"} element={<ChangeLog />} />
                {/* <Route path={"/sign-up"} element={<SignUp />} /> */}
                {/* Authentication Error Pages */}
                <Route
                  path={`${process.env.PUBLIC_URL}`}
                  element={<ErrorPages />}
                >
                  <Route path={"error-400"} element={<Error400 />} />
                  <Route path={"error-401"} element={<Error401 />} />
                  <Route path={"error403"} element={<Error403 />} />
                  <Route path={"error-404"} element={<Error404 />} />
                  <Route path={"error-500"} element={<Error500 />} />
                  <Route path={"error-503"} element={<Error503 />} />,
                  <Route path={"no-permission"} element={<NoPermission />} />,
                  <Route
                    path={"error-token-expired"}
                    element={<TokenExpired />}
                  />
                  ,
                  {/* <Route path={"no-token"} element={<NoToken />} />,
                  <Route path={"token-expired"} element={<NoToken />} />, */}
                </Route>

                {/* switcher */}
                <Route>
                  <Route
                    path={"pages/switcher/switcherstyle1"}
                    element={<SwitcherApp />}
                  />
                </Route>

                {/* Error */}
                <Route path="*" element={<Error500 />} />
              </Routes>
            </AuthenProvider>
          </React.Suspense>
        </BrowserRouter>
      </Fragment>

      <ToastContainer />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
