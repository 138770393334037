import { AxiosResponse } from "axios";
import ServerResponse from "../models/ServerResponse";
import axiosClient from "./baseApi";

export const DashboardQueryKey: any = {
  getAllDashBoard: "getAllDashBoard",
};

export const getAllDashBoard = async (params: any) => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(
      "/dashboard/data-dashboard",
      { params }
    );
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
export const getAllFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>("/brand/getFilter");
    return await Promise.resolve(res?.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
