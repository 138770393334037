import { useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import {
  getAllFilter as getAllPlace,
  placeQueryKey,
} from "../../services/apiPlace";

interface storeGroupProp {
  value: any | null;
  onChange: (value: any | null) => void;
  loading: boolean;
}

const SelectStoreFilter = ({ loading, value, onChange }: storeGroupProp) => {
  const [isLoading, setIsLoading] = useState(false);

  const { data, status } = useQuery<any>(
    [placeQueryKey?.getAllPlace],
    () => {
      setIsLoading(true);
      return getAllPlace();
    },
    {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  const options: any =
    data?.data?.map((place: any) => ({
      label: place?.name,
      value: place?.place_id,
    })) ?? [];

  return (
    <div className="select-user">
      <Select
        className="select-filter-items custom-inner"
        classNamePrefix="select"
        placeholder="Select"
        isDisabled={loading}
        options={options}
        value={value}
        onChange={onChange}
        isClearable
        isLoading={isLoading || status === "loading"}
      />
    </div>
  );
};

export default SelectStoreFilter;
