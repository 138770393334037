import { FC } from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface LoaderProps {}

const LoadingSkeletonDashboard: FC<LoaderProps> = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "50",
        }}
      >
        <Skeleton count={1} style={{ marginBottom: "0.5rem" }} />
        <Skeleton count={1} style={{ marginBottom: "0.5rem" }} />
        <Skeleton count={1} style={{ marginBottom: "0.5rem" }} />
        <Skeleton count={1} style={{ marginBottom: "0.5rem" }} />
        <Skeleton count={1} style={{ marginBottom: "0.5rem" }} />
        <Skeleton count={1} style={{ marginBottom: "0.5rem" }} />
        <Skeleton count={1} style={{ marginBottom: "0.5rem" }} />
        <Skeleton count={1} style={{ marginBottom: "0.5rem" }} />
      </div>
    </div>
  );
};

export default LoadingSkeletonDashboard;
